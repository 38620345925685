@import '@glidejs/glide/src/assets/sass/glide.core.scss';
@import '@glidejs/glide/src/assets/sass/glide.theme.scss';
:root {
  --brand-color-1: #101820;
  --brand-color-2: #e1b87f;
  --brand-color-3: #cbc4bc;
  --brand-color-4: #425563;
}
a,
a:visited {
  color: white;
  text-decoration: none;
}

a:hover {
  color: var(--brand-color-2);
}

body {
  padding: 0;
  margin: 0;
  color: white;
  font-family: sans-serif;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.justify-center {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}

header {
  display: block;
}

.logo {
  height: 40vh;
  width: 40vh;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.links {
  font-size: 20px;
}

.links li {
  padding: 0.5rem;
}

.links li a {
  display: flex;
}

.links li span {
  display: block;
}

.links li img {
  display: block;
  filter: invert(100%);
  width: 1em;
  height: 1em;
  padding-right: 1em;
}

.heading-text__title {
  font-size: 2.5rem;
}

.heading-text__subtitle {
  font-size: 1.5rem;
  font-weight: 100;
  font-style: italic;
}

.container article {
  width: 690px;
}
.background-1 {
  background-color: var(--brand-color-1);
  color: white;
}
.background-2 {
  background-color: var(--brand-color-2);
  color: var(--brand-color-1);
}
.background-3 {
  background-color: var(--brand-color-3);
  color: var(--brand-color-1);
}
.background-4 {
  background-color: var(--brand-color-4);
  color: white;
}

.nav {
  left: calc(50% - 10rem);
  position: fixed;
  bottom: 2rem;
  width: 20rem;
  display: grid;
  justify-items: center;
  // one column removed
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 999;
}

.nav__button {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.15);
  /* background-color: rgba(255,255,255,0.5); */
}

.nav__button:hover,
.nav__button:focus,
.nav__button:active {
  filter: opacity(50%);
}

.image-container {
  display: flex;
  height: 70vh;
  width: 100vw;
  /* justify-content: center; */
}

.image-container img {
  /* height: 100%; */
  height: 100%;
  -o-object-fit: fit;
  object-fit: cover;
  margin: 0 auto;
}

.testimonial__name {
  margin-right: 3rem;
  text-align: right;
  font-style: oblique;
}

.testimonial__name::before {
  content: "- ";
}

blockquote {
  font-family: Georgia, serif;
  position: relative;
  margin: 0.5em;
  padding: 0.5em 2em 0.5em 3em;
}
blockquote:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 6em;
  line-height: 1;
  top: 0;
  left: 0;
  content: "\201C";
}
blockquote:after {
  font-family: Georgia, serif;
  position: absolute;
  float: right;
  font-size: 6em;
  line-height: 1;
  right: 0;
  bottom: -0.5em;
  content: "\201D";
}

.testimonial__quote {
  font-style: oblique;
}

.body-text {
  line-height: 1.9rem;
    font-size: 1.3rem;
}

@media only screen and (max-width: 690px) {
  .logo {
    margin-top: -3rem;
  }
  .container {
    flex-direction: column;
  }
  .links {
    padding: 2rem 0 0 2rem;
    font-size: 16px;
  }
  .links h1 {
    display: none;
  }
  .container article {
    width: 90vw;
  }

  .image-container img {
    /* height: 100%; */
    width: 100%;
  }

  .nav {
    left: calc(50% - 50vw);
    width: 100vw;
  }


  .heading-text__title {
    font-size: 2rem;
  }
  
  .heading-text__subtitle {
    font-size: 1.5rem;
    font-weight: 100;
    font-style: italic;
  }

  .body-text {
    line-height: 1.3rem;
      font-size: 1rem;
      padding: 2rem;
  }
}
